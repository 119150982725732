import { useState } from "react";
import { Link } from "react-router-dom";
import img1 from "../../../img/branco.png";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import YoutubeIcon from "@material-ui/icons/YouTube";
import PlayIcon from "../../../img/play.png";

import "./mobile.css";
import "./navbar.css";

export function NavBar(page: any) {
  const [menuDisplay, setMenuDisplay] = useState<boolean>(false);

  function handleClick(showOrHide: boolean): void {
    setMenuDisplay(showOrHide);
  }

  function displayRightMenu(value: boolean) {
    if (value) return "backgroundRightMenuDisplay";
    return "backgroundRightMenuNoDisplay";
  }

  /*------------*/

  const aux = [page.page];

  const [currentPage, setCurrentPage] = useState<string[]>(aux);

  function changeColor() {
    switch (currentPage[0]) {
      case "home":
        setCurrentPage(["active", ""]);
        break;

      case "credenciar":
        setCurrentPage(["", "active"]);
        break;

      default:
        break;
    }
  }

  changeColor();

  function changeColorDestaque(value: string) {
    if (value === "active") return "colorDestaqueAba";
    return "noColorDestaqueAba";
  }

  /*------------*/

  return (
    <>
      <div className="d-flex justify-content-end">
        <div className={displayRightMenu(menuDisplay)}>
          <div
            style={{ width: 213, zIndex: 999 }}
            className="flex-column justify-content-center "
          >
            <div className="d-flex justify-content-end">
              <CloseIcon
                style={{ fontSize: 40 }}
                className="close-icon"
                onClick={() => handleClick(false)}
              />
            </div>
            <nav className="menu-container-mobile text-center">
              <div className="menu-mobile">
                <ul>
                <li style={{ fontSize: 15 }}>
                    <Link
                      to="/"
                    >
                      Credicare para você
                    </Link>
                  </li>
                  <div className="line" />
                  <li style={{ fontSize: 15 }}>
                    <Link
                      to="/minhaClinica"
                    >
                      Credenciar minha clínica
                    </Link>
                  </li>
                  {/* <li style={{ fontSize: 13, marginTop: 50 }}>
                    <a href="/">
                      <img
                        src={PlayIcon}
                        alt="playIcon"
                        className="play-icon mx-1"
                      />
                      Assista ao vídeo
                    </a>
                  </li> */}
                </ul>
              </div>
            </nav>
            <div className="d-flex justify-content-around mx-5">
              <a href="https://www.facebook.com/credicare" target="_blank">
                <FacebookIcon
                  style={{ fontSize: 22 }}
                  className="social-icons mx-2"
                />
              </a>
              <a href="https://www.instagram.com/_credicare/" target="_blank">
                <InstagramIcon
                  style={{ fontSize: 22 }}
                  className="social-icons mx-2"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div id="nav-Simul">
        <nav className="menu-container justify-content-between align-items-center">
          <a href="/">
            <div>
              <img
                src={img1}
                alt="Logo Credicare"
                className="img-fluid logoCrediCare"
              />
            </div>
          </a>

          <nav className="navbar-dark toogleNavBar">
            <MenuIcon
              onClick={() => handleClick(true)}
              style={{ fontSize: 40 }}
            />
          </nav>

          <div className="menu justify-content-end">
            <ul className="align-items-center">
              {/* <li
                style={{ maxWidth: 300 }}
                className="d-flex align-items-center"
              >
                <a href="/">
                  <img
                    src={PlayIcon}
                    alt="playIcon"
                    className="play-icon mx-1"
                  />
                  Assista ao vídeo
                </a>
              </li> */}
              <li style={{ maxWidth: 300 }}>
                <Link to="/" className={changeColorDestaque(currentPage[0])}>
                Credicare para você
                </Link>
              </li>
              <li style={{ maxWidth: 300 }}>
                <Link
                  to="/minhaClinica"
                  className={changeColorDestaque(currentPage[1])}
                >
                  Credenciar minha clínica
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}
