import img1 from "../../../img/azul.png";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import "./footer.css";

export function Footer() {
  return (
    <>
      <div id="bloco-footer" className="flex-column d-flex align-items-center">
        <img src={img1} alt="Logo Credicare" className="centerLogoFooter" />
        <div>
          <a href="https://www.facebook.com/credicare" target="_blank">
            <FacebookIcon
              style={{ fontSize: 22 }}
              className="social-icons mx-2"
            />
          </a>
          <a href="https://www.instagram.com/_credicare/" target="_blank">
            <InstagramIcon
              style={{ fontSize: 22 }}
              className="social-icons mx-2"
            />
          </a>
        </div>
      </div>
      <div className="d-none d-md-block" style={{ background: "#f4f4f4" }}>
        <div className="d-flex">
          <div className="blocoAzulFooter" />
          <div className="blocoCianoFooter" />
        </div>
      </div>
    </>
  );
}
