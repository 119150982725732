//import React, { useState } from 'react';
import { NavBar3 } from "../components/Global/Navbar/Navbar3";
import { Header2 } from "../components/Index-1/Header";
import { SecondBloco1 } from "../components/Index-1/Bloco1";
import { SecondBloco2 } from "../components/Index-1/Bloco2";
import { Footer } from "../components/Global/Footer/footer";

export function Second() {
  return (
    <>
      <NavBar3 page="credenciar"/>
      <Header2 />
      <SecondBloco1 />
      <SecondBloco2 />
      <Footer />
    </>
  );
}
