import img1 from "../../img/Smartphone.png";
import "../../styles/global.css";
import "../../styles/bloco2.css";
import Smile from "../../img/Smile.png";
import Seta from "../../img/seta.png";
import { Slider } from "../Global/slider";
import WhatsappIcon from "@material-ui/icons/WhatsApp";

export function IndexBloco2() {
  return (
    <div className="px-5" style={{ marginTop: 50 }}>
      <div id="bloco2" className="row justify-content-center">
        <div
          className="row px-md-5  justify-content-between"
          style={{ maxWidth: 1440 }}
        >
          <div id="coluna1">
            <h1>Passo a passo para contratar o empréstimo na Credicare</h1>
          </div>
          <div id="coluna2">
            <div>
              <div>
                <h2>1. Simulação</h2>
              </div>
              <div id="passoApassoDescricao">
                <span>
                  Clique no botão SIMULE AGORA e informe o valor do crédito
                  necessário, com parcelas que cabem no seu bolso.
                </span>
              </div>
            </div>
            <img src={Seta} alt="Seta" className="img-fluid" />
            <div className="mt-4">
              <div>
                <h2>2. Análise e aprovação</h2>
              </div>
              <div id="passoApassoDescricao">
                <span>
                  Com o envio dos documentos necessários, a análise do cadastro
                  é realizada, sendo aprovado, o crédito é liberado!
                </span>
              </div>
            </div>
            <img src={Seta} alt="Seta" className="img-fluid" />
            <div className="mt-4">
              <div>
                <h2>3. Crédito na mão</h2>
              </div>
              <div id="passoApassoDescricao">
                <span>
                  Com o cadastro aprovado, o crédito é disponibilizado
                  diretamente para a clínica/consultório, que realizará seu
                  procedimento. Simples assim!
                </span>
              </div>
            </div>
            <img src={Seta} alt="Seta" className="img-fluid" />
            <div className="mt-4">
              <a href="https://wa.me/5534997118850" target="_blank">
                <button className="buttonSimul ">
                  <div className="alignbutton">
                    <WhatsappIcon
                      className="social-icons-write mx-2"
                      style={{ marginBottom: 5 }}
                    />
                    <span>SIMULE AGORA</span>
                  </div>
                </button>
              </a>
            </div>
          </div>
          <div id="coluna3Smartphone" className="pt-md-0 px-md-5">
            <img src={img1} alt="Device" className="img-fluid" />
          </div>
        </div>

        {/* <div>
		<div className="d-flex ">
		  <div className="d-flex px-5 w-100 row justify-content-center">
			<h1 id="text4-section2" className="" style={{ width: 1245 }}>
			  Depoimentos de quem está recebendo nossos cuidados financeiros
			  <div>
				<img src={Smile} alt="Smile" className="img-fluid" />
			  </div>
			</h1>
		  </div>
		  <div className="d-none d-md-block">
			<div className="d-flex align-items-end flex-column">
			  <div className="blocoCiano" />
			  <div className="blocoAzul" />
			</div>
		  </div>
		</div>
	  </div>

	  <div className="row justify-content-center">
		<div className="mx-5" style={{ width: 1320 }}>
		  <Slider />
		</div>
	  </div> */}
      </div>
    </div>
  );
}
