import { NavBar } from "../Global/Navbar/Navbar";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import YoutubeIcon from "@material-ui/icons/YouTube";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import Smile from "../../img/Smile.png";
import PlayIcon from "../../img/play.png";
import ArrowDown from "../../img/arrow-down.png";
import { WhatsappComponent } from "../Global/whatsappComponent";

import "../../styles/header.css";

export function Header2() {
  return (
    <>
      <WhatsappComponent />

      <div id="SecondHeader">
        <div id="BackgroundHeader2">
          <NavBar page="credenciar" />

          <div className="bodyHeader">
            <div className="px-5">
              <div id="container0Header">
                <h3 id="textHeader0">PARA SUA CLÍNICA!</h3>
                <div style={{ maxWidth: 40 }}>
                  <img
                    src={Smile}
                    alt="Smile"
                    className="img-fluid smileIcon"
                  />
                </div>
              </div>
              <div id="container1Header">
                <h1 id="textHeader1">Venda mais, receba na hora!</h1>
                <div id="container2Header" className="mt-4">
                  <h2 id="textHeader2">
                    Você cuida do seu cliente e nós cuidamos do crédito dele.
                  </h2>
                </div>

                {/* <div className="d-flex justify-content-end">
                                    <button className="botaoVideo">
                                        Assista ao vídeo
                                        <img
                                        src={PlayIcon}
                                        alt="playIcon"
                                        className="play-icon mx-2"
                                        />
                                    </button>
                                </div> */}

                <a href="https://wa.me/5534997118850" target="_blank">
                  <button className="button">
                    <div className="alignbutton">
                      <WhatsappIcon
                        className="social-icons-write mx-2"
                        style={{ marginBottom: 5 }}
                      />
                      <span>QUERO SABER MAIS</span>
                    </div>
                  </button>
                </a>

                <div className="socials">
                  <a href="https://www.facebook.com/credicare" target="_blank">
                    <FacebookIcon
                      style={{ fontSize: 22 }}
                      className="social-icons-write mx-2"
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/_credicare/"
                    target="_blank"
                  >
                    <InstagramIcon
                      style={{ fontSize: 22 }}
                      className="social-icons-write mx-2"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="iconArrowDown d-flex justify-content-center">
              <img src={ArrowDown} alt="Arrow Down" className="arrowDownIcon" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
