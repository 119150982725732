//import React, { useState } from 'react';
import { NavBar3 } from "../components/Global/Navbar/Navbar3";
import { Header } from "../components/Index/Header";
import { IndexBloco1 } from "../components/Index/Bloco1";
import { IndexBloco2 } from "../components/Index/Bloco2";
import { Footer } from "../components/Global/Footer/footer";

export function Index() {
  return (
    <>
      <NavBar3 page="home"/>
      <Header />
      <IndexBloco1 />
      <IndexBloco2 />
      <Footer />
    </>
  );
}
