import img1 from "../../img/graph.png";
import "../../styles/bloco1.css";

export function SecondBloco1() {
    return (
        <div id="container1-section1">
            <div className="row justify-content-center" style={{ marginTop: 120 }}>
                <div className="py-3 px-md-5" style={{ maxWidth: 1440 }}>
                    <div className="row px-5  justify-content-between">
                        <div
                            id="container1-first"
                            className="col-12 col-lg-6 align-self-center"
                        >
                            <h1>
                                Por que ser um credenciado e participar dessa rede ganha ganha
                            </h1>
                            <div className="pt-4">
                                <li id="item">Aumente o número de pacientes</li>
                                <li id="item">Ofereça comodidade financeira</li>
                                <li id="item">Facilite a realização de procedimentos</li>
                                <li id="item">Garanta estabilidade no seu fluxo de caixa</li>
                            </div>
                        </div>
                        <div className="d-flex align-self-center" style={{ maxWidth: 450 }}>
                            <img src={img1} alt="Graph" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="d-none d-lg-block">
                    <div className="d-flex align-items-end flex-column">
                        <div className="blocoCiano" />
                        <div className="blocoAzul" />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="py-3 px-md-5" style={{ maxWidth: 1440 }}>
                        <div className="px-5">
                            <div id="container1-second">
                                <h1>
                                    Conheça as vantagens do empréstimo para seu tratamento
                                    odontológico
                                </h1>
                                <div className="pt-4 d-flex">
                                    <h3>
                                        Implemente o sistema de crédito dos procedimentos realizados
                                        em sua clínica ou consultório e veja a diferença em seu
                                        faturamento.
                                    </h3>
                                </div>
                            </div>
                            <div id="passoAPasso">
                                <div className="row px-3 pt-5">
                                    <div className="d-flex" style={{ marginBottom: 80 }}>
                                        <div className="row d-flex justify-content-left">
                                            <div className="bloco-section1">
                                                <h2>Nada burocrático</h2>
                                                <div style={{ width: 250 }}>
                                                    <span>Todo processo é online.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex" style={{ marginBottom: 150 }}>
                                        <div className="row d-flex justify-content-left">
                                            <div className="bloco-section1">
                                                <h2>Dinheiro na hora</h2>
                                                <div style={{ width: 250 }}>
                                                    <span>
                                                        Crédito à vista, na conta da clínica/consultório, no
                                                        valor total do procedimento.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex" style={{ marginBottom: 140 }}>
                                        <div className="row d-flex justify-content-left">
                                            <div className="bloco-section1">
                                                <h2>Risco zero</h2>
                                                <div style={{ width: 250 }}>
                                                    <span>
                                                        O financiamento é feito pela Credicare, sem riscos
                                                        para seu negócio.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-start flex-column d-none d-lg-block">
                <div className="blocoCiano" />
                <div className="blocoAzul" />
            </div>
        </div>
    );
}
