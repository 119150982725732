import img1 from "../../img/Computer.png";
import "../../styles/global.css";
import "../../styles/bloco2.css";
import Smile from "../../img/Smile.png";
import Seta from "../../img/seta.png";
import { Slider } from "../Global/slider";
import WhatsappIcon from "@material-ui/icons/WhatsApp";

export function SecondBloco2() {
  return (
    <div className="px-5" style={{ marginTop: 50 }}>
      <div id="bloco2" className="row justify-content-center">
        <div
          className="row px-md-5  justify-content-between"
          style={{ maxWidth: 1440 }}
        >
          <div id="coluna1Notebook">
            <h1>
              Passo a passo para começar a ganhar mais com nossas soluções
              financeiras
            </h1>
          </div>
          <div id="coluna2Notebook">
            <div>
              <div>
                <h2>1. Entre em contato</h2>
              </div>
              <div id="passoApassoDescricao">
                <span>
                  Clique no botão ENTRE EM CONTATO e converse com nossos
                  consultores.
                </span>
              </div>
            </div>
            <img src={Seta} alt="Seta" className="img-fluid" />
            <div className="mt-4">
              <div>
                <h2>2. Cadastre-se</h2>
              </div>
              <div id="passoApassoDescricao">
                <span>
                  Com o envio dos seus documentos faremos a análise e aprovação
                  do seu cadastro.
                </span>
              </div>
            </div>
            <img src={Seta} alt="Seta" className="img-fluid" />
            <div className="mt-4">
              <div>
                <h2>3. Venda mais</h2>
              </div>
              <div id="passoApassoDescricao">
                <span>
                  Com o cadastro aprovado, agora é só oferecer o crédito para
                  seus pacientes. Detalhe, o crédito com o valor dos
                  procedimentos realizados cai direto na conta da sua clínica.
                </span>
              </div>
            </div>
            <img src={Seta} alt="Seta" className="img-fluid" />
            <div className="mt-4">
              <a href="https://wa.me/5534997118850" target="_blank">
                <button className="buttonSimul">
                  <div className="alignbutton">
                    <WhatsappIcon
                      className="social-icons-write mx-2"
                      style={{ marginBottom: 5 }}
                    />
                    <span>ENTRE EM CONTATO</span>
                  </div>
                </button>
              </a>
            </div>
          </div>
          <div id="coluna3Notebook" className="pt-md-0 px-md-5">
            <img src={img1} alt="Device" className="img-fluid" />
          </div>
        </div>
      </div>

      {/* <div style={{ marginTop: 96 }}>
        <div className="d-flex">
          <div className="d-flex px-5 w-100 row justify-content-center">
            <h1 id="text4-section2" className="" style={{ width: 1245 }}>
              Depoimentos de quem está recebendo nossos cuidados financeiros
              <div>
                <img src={Smile} alt="Smile" className="img-fluid" />
              </div>
            </h1>
          </div>
          <div className="d-none d-md-block">
            <div className="d-flex align-items-end flex-column">
              <div className="blocoCiano" />
              <div className="blocoAzul" />
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="mx-5" style={{ width: 1320 }}>
          <Slider />
        </div>
      </div> */}
    </div>
  );
}
