import CloseIcon from "@material-ui/icons/Close";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import { useState } from "react";
import "../../styles/whatsappComponent.css";

export function WhatsappComponent() {
  const [popUpWhatsapp, setPopUpWhatsapp] = useState<boolean>(false);

  function iconWhatsappComponent(value: boolean) {
    if (value) return "mainBackgroundUncollapsed";
    return "mainBackgroundCollapsed";
  }

  function iconWhatsappOpened(value: boolean) {
    if (value) return "componentUncollapsed";
    return "componentCollapsed";
  }

  function iconCloseButton(value: boolean) {
    if (value) return "closeIconClose";
    return "closeIconOpen";
  }

  return (
    <div className="justify-content-center componentBody">
      <div className="toogleIcon">
        <CloseIcon
          className={iconCloseButton(popUpWhatsapp)}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#popUpWhatsapp"
          aria-expanded="false"
          aria-controls="popUpWhatsapp"
          onClick={() => setPopUpWhatsapp(!popUpWhatsapp)}
        />
      </div>
      <div className={iconWhatsappComponent(popUpWhatsapp)}>
        <div>
          <button
            className={iconWhatsappOpened(popUpWhatsapp)}
            id="bodyWhatsapp"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#popUpWhatsapp"
            aria-expanded="false"
            aria-controls="popUpWhatsapp"
            onClick={() => setPopUpWhatsapp(!popUpWhatsapp)}
            style={{ alignSelf: "center" }}
          >
            <WhatsappIcon
              style={{ fontSize: 24, marginBottom: 5 }}
              className="social-icons-write mx-2"
            />
          </button>
        </div>

        <div className="collapse" id="popUpWhatsapp">
          <div>
            <h2 className="mainMessage">Vamos nos falar por Whatsapp?</h2>
            <div className="d-flex collapse">
              <div className="avatar">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLbzdbxloWNKnGd1aL4rDsjL03id9Dk8yuL9NgQBS4r256pFbVgYcoS2rtnO98clFLVlU&usqp=CAU"
                  alt="avatar"
                  className="whatsappIcon"
                />
              </div>
              <div>
                <div className="nomeContato">Credicare</div>
                <div className="descriptionContato">Atendimento Digital</div>
                <div className="statusContato">Online</div>
              </div>
            </div>
            <div className="lineTopButton"></div>
            <a href="https://wa.me/5534997118850" target="_blank">
              <button className="comecarButton">começar</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
