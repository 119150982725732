import img1 from "../../img/graph.png";
import "../../styles/bloco1.css";

export function IndexBloco1() {
     return (
          <div id="container1-section1">
               <div className="row justify-content-center" style={{ marginTop: 120 }}>
                    <div className="py-3 px-md-5" style={{ maxWidth: 1440 }}>
                         <div className="row px-5 justify-content-between">
                              <div id="container1-first" className="col-12 col-lg-6 align-self-center">
                                   <h1>
                                       Por que fazer um empréstimo para seu tratamento odontológico?
                                    </h1>
                                   <div className="pt-4 d-flex">
                                        <h3>
                                             Muitas pessoas adiam tratamento dentário por falta de recursos financeiros
                                             disponíveis no momento do orçamento. Elas acabam desistindo ou postergando a saúde
                                             bucal e a qualidade de vida. A Credicare traz as melhores opções de financiamento
                                             para realização do seu sonho.
                                        </h3>
                                   </div>
                              </div>
                              <div className="d-flex align-self-center" style={{ maxWidth: 450 }}>
                                   <img src={img1} alt="Graph" className="img-fluid" />
                              </div>
                         </div>
                    </div>
               </div>
               <div>
                    <div className="d-none d-lg-block">
                         <div className="d-flex align-items-end flex-column">
                              <div className="blocoCiano" />
                              <div className="blocoAzul" />
                         </div>
                    </div>
                    <div className="row justify-content-center">
                         <div className="py-3 px-md-5" style={{ maxWidth: 1440 }}>
                              <div className="px-5">
                                   <div id="container1-second">
                                        <h1>
                                             Conheça as vantagens do empréstimo para o tratamento odontológico de seus clientes
                                        </h1>
                                        <div className="pt-4 d-flex">
                                             <h3>
                                                  O Financiamento pela CrediCare é a melhor forma de transformar o seu sorriso,
                                                  recuperando sua auto-estima e bem-estar. Com a CrediCare você terá a garantia de
                                                  pagar as menores taxas de juros disponíveis no mercado.
                                             </h3>
                                        </div>
                                   </div>
                                   <div id="passoAPasso">
                                        <div className="row px-3 pt-5">
                                             <div className="d-flex" style={{ marginBottom: 150 }}>
                                                  <div className="row d-flex justify-content-left">
                                                       <div className="bloco-section1">
                                                            <h2>Nada burocrático</h2>
                                                            <div style={{ minWidth: 250 }}>
                                                                 <span>
                                                                      Desde a simulação até a liberação do dinheiro: tudo é 100% online.
                                                                 </span>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="d-flex" style={{ marginBottom: 180 }}>
                                                  <div className="row d-flex justify-content-left">
                                                       <div className="bloco-section1">
                                                            <h2>Juros baixos</h2>
                                                            <div style={{ minWidth: 250 }}>
                                                                 <span>
                                                                      Opção ideal para quem quer parcelas que garantam uma vida financeira
                                                                      saudável.
                                                                 </span>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="d-flex" style={{ marginBottom: 150 }}>
                                                  <div className="row d-flex justify-content-left">
                                                       <div className="bloco-section1">
                                                            <h2> Atendimento personalizado</h2>
                                                            <div style={{ minWidth: 250 }}>
                                                                 <span>
                                                                      Nosso time de atendimento vai conversar com você para ofertar a melhor
                                                                      solução disponível.
                                                                 </span>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <div className="d-flex align-items-start flex-column d-none d-lg-block">
                    <div className="blocoCiano" />
                    <div className="blocoAzul" />
               </div>
          </div>
     );
}
